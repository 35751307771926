
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatI1(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__empty'
                    });
                }
                return _createElement('li', { 'className': 'product' }, _createElement('article', {
                    'className': 'card',
                    'data-test': 'card-' + this.id
                }, _createElement('figure', { 'className': 'card-figure' }, this.on_sale ? _createElement('div', {
                    'className': 'card-badge',
                    'key': '112'
                }, _createElement('span', {}, 'Sale')) : null, _createElement('a', {
                    'href': this.custom_url,
                    'className': 'card-figure__link',
                    'aria-label': 'this.removeHTML(this.name)'
                }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                    'src': this.imageOrDefault(this.images_image_thumbnail1),
                    'alt': this.removeHTML(this.name),
                    'title': this.removeHTML(this.name),
                    'onError': this.onImageError,
                    'data-sizes': 'auto',
                    'className': 'card-image lazyautosizes ls-is-cached lazyloaded',
                    'loading': 'lazy',
                    'sizes': '284px'
                })))), _createElement('div', { 'className': 'card-body' }, _createElement('div', { 'className': 'cm_review-stars-container' }, this.review_count ? _createElement('a', {
                    'href': this.custom_url,
                    'className': 'cm_review-stars-link',
                    'key': '742'
                }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_review-stars' },
                    _map([
                        0,
                        1,
                        2,
                        3,
                        4
                    ], repeatI1.bind(this)),
                    _createElement('span', {}, '(', this.review_count, ')')
                ])) : null, !this.review_count ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_review-stars',
                        'key': '1218'
                    },
                    _map([
                        0,
                        1,
                        2,
                        3,
                        4
                    ], repeatI2.bind(this)),
                    _createElement('span', {}, '(0)')
                ]) : null, _createElement('a', {
                    'href': this.custom_url + '#write_review',
                    'className': 'productView-reviewLink productView-reviewLink--new',
                    'role': 'button'
                }, '\n          Write a review\n        ')), _createElement('div', { 'className': 'product-name title-custom-field--wrap match-height' }, this.sku ? _createElement('div', {
                    'className': 'sku',
                    'key': '1671'
                }, '\n          Product #:\n          ', _createElement('div', Object.assign({}, { 'data-product-sku': true }, { dangerouslySetInnerHTML: { __html: this.sku } }))) : null, _createElement('h3', { 'className': 'card-title' }, _createElement('a', Object.assign({}, {
                    'aria-label': 'this.removeHTML(this.name)',
                    'href': this.custom_url
                }, { dangerouslySetInnerHTML: { __html: this.name } })))), _createElement('div', {
                    'className': 'prices',
                    'data-test-info-type': 'price'
                }, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('span', {
                    'data-product-price-without-tax': true,
                    'className': 'price price--withoutTax'
                }, '\n            ', this.formatPrice(this.price), '\n          ')), this.on_sale ? _createElement('div', {
                    'className': 'price-section price-section--withoutTax non-sale-price--withoutTax',
                    'key': '2320'
                }, _createElement('span', {
                    'data-product-non-sale-price-without-tax': true,
                    'className': 'price price--non-sale'
                }, '\n            ', this.formatPrice(this.regular_price), '\n          ')) : null, this.on_sale && this.retail_price > this.price ? _createElement('div', {
                    'className': 'price-discount',
                    'key': '2604'
                }, '\n          You save ', this.formatPrice(this.retail_price - this.price), ' (', this.calcDiscount(this.price, this.retail_price), '%)\n        ') : null), _createElement('div', {
                    'className': 'card-quantity',
                    'data-quantity-change': true
                }, _createElement('button', {
                    'className': 'button button--icon left',
                    'data-action': 'dec'
                }, _createElement('span', { 'className': 'is-srOnly' }, 'Decrease quantity'), _createElement('svg', {
                    'width': '18',
                    'height': '18',
                    'viewBox': '0 0 18 18',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg'
                }, _createElement('g', { 'clipPath': 'url(#clip0_475_2069)' }, _createElement('path', {
                    'd': 'M2.18182 8.32251H15.2727V9.77706H2.18182V8.32251Z',
                    'fill': '#3B363E'
                })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_475_2069' }, _createElement('rect', {
                    'width': '17.4545',
                    'height': '17.4545',
                    'fill': 'white',
                    'transform': 'translate(0 0.272705)'
                }))))), _createElement('label', {
                    'htmlFor': 'qty-' + this.id + '[]',
                    'className': 'hidden'
                }, 'Quantity'), _createElement('input', {
                    'className': 'form-input form-input--incrementTotal',
                    'id': 'qty-' + this.id + '[]',
                    'name': 'qty[]',
                    'type': 'tel',
                    'defaultValue': '1',
                    'data-quantity-min': true,
                    'data-quantity-max': true,
                    'min': '1',
                    'pattern': '[0-9]*',
                    'aria-live': 'polite'
                }), _createElement('button', {
                    'className': 'button button--icon right',
                    'data-action': 'inc'
                }, _createElement('span', { 'className': 'is-srOnly' }, 'Increase quantity'), _createElement('svg', {
                    'width': '11',
                    'height': '12',
                    'viewBox': '0 0 11 12',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg'
                }, _createElement('path', {
                    'd': 'M10.3636 6.72736H6.00001V11.091H4.54546V6.72736H0.181824V5.27282H4.54546V0.90918H6.00001V5.27282H10.3636V6.72736Z',
                    'fill': '#3B363E'
                })))), window.Convermax.isLoggedIn ? [
                    !this.has_options && !this.out_of_stock ? _createElement('a', {
                        'href': '/cart.php?action=add&product_id=' + this.id + '&qty=1',
                        'data-event-type': 'product-click',
                        'data-button-type': 'add-cart',
                        'className': 'button button--terciary cta-btn button button--small card-quick-add-button',
                        'key': '42791'
                    }, _createElement('span', { 'className': 'cta-btn--inner' }, _createElement('svg', {
                        'width': '25',
                        'height': '24',
                        'viewBox': '0 0 25 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg'
                    }, _createElement('g', { 'clipPath': 'url(#clip0_266_1653)' }, _createElement('path', {
                        'd': 'M8.33326 17C7.41659 17 6.67492 17.75 6.67492 18.6667C6.67492 19.5834 7.41659 20.3334 8.33326 20.3334C9.24993 20.3334 9.99993 19.5834 9.99993 18.6667C9.99993 17.75 9.24993 17 8.33326 17ZM3.33325 3.66669V5.33336H4.99992L7.99993 11.6584L6.87492 13.7C6.74159 13.9334 6.66659 14.2084 6.66659 14.5C6.66659 15.4167 7.41659 16.1667 8.33326 16.1667H18.3333V14.5H8.68326C8.56659 14.5 8.47493 14.4084 8.47493 14.2917L8.49993 14.1917L9.24993 12.8334H15.4583C16.0833 12.8334 16.6333 12.4917 16.9166 11.975L19.8999 6.56669C19.9666 6.45003 19.9999 6.30836 19.9999 6.16669C19.9999 5.70836 19.6249 5.33336 19.1666 5.33336H6.84159L6.05826 3.66669H3.33325ZM16.6666 17C15.7499 17 15.0083 17.75 15.0083 18.6667C15.0083 19.5834 15.7499 20.3334 16.6666 20.3334C17.5833 20.3334 18.3333 19.5834 18.3333 18.6667C18.3333 17.75 17.5833 17 16.6666 17Z',
                        'fill': '#AF1C21'
                    })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_266_1653' }, _createElement('rect', {
                        'width': '20',
                        'height': '20',
                        'fill': 'white',
                        'transform': 'translate(2.49997 2)'
                    })))), _createElement('span', {}, 'Add to Cart'))) : null,
                    this.has_options && !this.out_of_stock ? _createElement('a', {
                        'href': this.custom_url,
                        'data-event-type': 'product-click',
                        'className': 'button button--terciary cta-btn button button--small card-quick-add-button choose-options-btn',
                        'data-product-id': this.id,
                        'key': '42793'
                    }, '\n          Choose Options\n        ') : null,
                    this.out_of_stock ? _createElement('a', {
                        'className': 'button button--terciary cta-btn button button--small card-quick-add-button out-of-stock-btn',
                        'key': '42795'
                    }, '\n          Out of Stock\n        ') : null
                ] : null, !window.Convermax.isLoggedIn ? [_createElement('a', {
                        'href': this.custom_url,
                        'data-event-type': 'product-click',
                        'className': 'button button--terciary cta-btn button button--small card-quick-add-button',
                        'data-product-id': this.id,
                        'key': '64771'
                    }, '\n          Learn More\n        ')] : null, _createElement('div', { 'className': 'bottom-actions' }, _createElement('label', {
                    'className': 'button button--small button--compare',
                    'htmlFor': 'compare-' + this.id
                }, '\n          Compare\n          ', _createElement('input', {
                    'type': 'checkbox',
                    'name': 'products[]',
                    'value': this.id,
                    'id': 'compare-' + this.id,
                    'data-compare-id': this.id,
                    'onClick': window.Convermax.handleProductCompareClick
                })), _createElement('a', {
                    'aria-controls': 'wishlist-dropdown',
                    'aria-expanded': 'false',
                    'className': 'dropdown-menu-button',
                    'data-dropdown': 'wishlist-dropdown',
                    'href': '#'
                }, _createElement('svg', {
                    'className': 'icon icon-icon-red-heart',
                    'viewBox': '0 0 32 32'
                }, _createElement('path', {
                    'fill': '#c10000',
                    'style': { fill: 'var(--color1, #c10000)' },
                    'd': 'M21.76 4.48c-2.227 0-4.365 1.037-5.76 2.675-1.395-1.638-3.533-2.675-5.76-2.675-3.942 0-7.040 3.098-7.040 7.040 0 4.838 4.352 8.781 10.944 14.771l1.856 1.677 1.856-1.69c6.592-5.978 10.944-9.92 10.944-14.758 0-3.942-3.098-7.040-7.040-7.040zM16.128 24.384l-0.128 0.128-0.128-0.128c-6.093-5.517-10.112-9.165-10.112-12.864 0-2.56 1.92-4.48 4.48-4.48 1.971 0 3.891 1.267 4.57 3.021h2.394c0.666-1.754 2.586-3.021 4.557-3.021 2.56 0 4.48 1.92 4.48 4.48 0 3.699-4.019 7.347-10.112 12.864z'
                }))), _createElement('ul', {
                    'aria-hidden': 'true',
                    'className': 'dropdown-menu',
                    'data-dropdown-content': true,
                    'id': 'wishlist-dropdown'
                }, _createElement('li', {}, _createElement('a', {
                    'data-wishlist': true,
                    'className': 'button',
                    'href': '/wishlist.php?action=add&product_id=' + this.id
                }, '\n              Add to My Wish List\n            ')), _createElement('li', {}, _createElement('a', {
                    'data-wishlist': true,
                    'className': 'button',
                    'href': '/wishlist.php?action=addwishlist&product_id=' + this.id
                }, '\n              Create New Wish List\n            ')))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products container productCarousel' }, _createElement.apply(this, [
        'ul',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []