
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function verifyFitmentRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                    var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                    var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                    var isPopularField = popularEntries.length && unpopularEntries.length;
                    return [
                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                            'value': '',
                            'key': 'null-option'
                        }, '\n          ', this.title, '\n        ') : null,
                        this.loading ? _createElement('option', {
                            'key': 'loading-option',
                            'disabled': true
                        }, '...loading...') : null,
                        isPopularField ? [
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '10481'
                            }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                            _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '10484'
                            }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                        ] : null,
                        _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                    ];
                }
                function scopeShowSelectedValues4() {
                    var showSelectedValues = this.selectedEntries.length;
                    return _createElement('option', {
                        'key': '_current',
                        'value': '_current'
                    }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                }
                function repeatEntry5(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3383'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3585'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select form-select',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'form-input cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2693'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    function repeatSelects2(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                    var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                    var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                    var isPopularField = popularEntries.length && unpopularEntries.length;
                    return [
                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                            'value': '',
                            'key': 'null-option'
                        }, '\n          ', this.title, '\n        ') : null,
                        this.loading ? _createElement('option', {
                            'key': 'loading-option',
                            'disabled': true
                        }, '...loading...') : null,
                        isPopularField ? [
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '10481'
                            }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                            _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '10484'
                            }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                        ] : null,
                        _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                    ];
                }
                function scopeShowSelectedValues4() {
                    var showSelectedValues = this.selectedEntries.length;
                    return _createElement('option', {
                        'key': '_current',
                        'value': '_current'
                    }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                }
                function repeatEntry5(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3383'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3585'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select form-select',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'form-input cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2693'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__verify-fitment' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '68'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, [_createElement('svg', {
            'width': '22',
            'height': '20',
            'viewBox': '0 0 22 20',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '2660'
        }, _createElement('path', {
            'd': 'M11 4.49L18.53 17.5H3.47L11 4.49ZM11 0.5L0 19.5H22L11 0.5ZM12 14.5H10V16.5H12V14.5ZM12 8.5H10V12.5H12V8.5Z',
            'fill': '#F29508'
        }))]), '\n      Add a vehicle to check fitment\n    '), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    ADD\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle,
        'role': 'button',
        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
    }, '\n    Clear\n  ')))) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label-container cm_' + this.fits,
        'key': '7145'
    }, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__' + this.fits + ' cmTemplate_locked',
        'key': '7257'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment__container' }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'width': '20',
            'height': '21',
            'viewBox': '0 0 20 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '75130'
        }, _createElement('path', {
            'd': 'M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM14.59 6.08L8 12.67L5.41 10.09L4 11.5L8 15.5L16 7.5L14.59 6.08Z',
            'fill': '#2A7E56'
        }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'width': '20',
            'height': '21',
            'viewBox': '0 0 20 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '80370'
        }, _createElement('path', {
            'd': 'M9 13.5H11V15.5H9V13.5ZM9 5.5H11V11.5H9V5.5ZM9.99 0.5C4.47 0.5 0 4.98 0 10.5C0 16.02 4.47 20.5 9.99 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 9.99 0.5ZM10 18.5C5.58 18.5 2 14.92 2 10.5C2 6.08 5.58 2.5 10 2.5C14.42 2.5 18 6.08 18 10.5C18 14.92 14.42 18.5 10 18.5Z',
            'fill': '#EB4714'
        }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '85080'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null, this.fits === 'aggressive' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-exclamation',
            'height': '20',
            'viewBox': '0 0 256 255',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '90160'
        }, _createElement('path', {
            'fillRule': 'evenodd',
            'clipRule': 'evenodd',
            'd': 'M112.098 0.528293C105.702 1.30529 89.9175 5.21029 83.6615 7.56329C64.7805 14.6653 49.2545 25.1013 34.5985 40.5423C-0.248547 77.2563 -9.69655 131.035 10.4785 177.833C17.3025 193.66 23.8655 203.124 37.5985 216.939C51.1805 230.602 61.9924 238.186 77.0984 244.649C104.915 256.549 138.414 258.074 166.974 248.741C177.998 245.139 194.9 236.465 203.598 229.946C212.511 223.266 228.177 206.84 233.748 198.332C262.341 154.67 262.457 100.262 234.048 56.8423C227.749 47.2143 210.42 29.3733 201.129 22.9513C185.936 12.4473 166.593 4.56829 148.217 1.39729C140.191 0.0112929 120.292 -0.466707 112.098 0.528293ZM146.598 101.364V157.864H127.598H108.598V101.364V44.8643H127.598H146.598V101.364ZM136.699 179.648C141.014 181.902 142.56 183.448 144.814 187.763C148.264 194.367 148.343 198.988 145.115 205.418C138.666 218.265 122.327 220.292 112.789 209.428C104.371 199.842 107.303 185.18 118.78 179.461C125.482 176.121 130.038 176.169 136.699 179.648Z'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, this.fits === 'yes' ? ['Compatible with'] : null, this.fits === 'no' ? ['Does Not Fit'] : null, this.fits === 'aggressive' ? ['Vehicle modifications may be required to fit your'] : null), _createElement('div', { 'className': 'cm_verify-fitment_body' }, _createElement('span', { 'className': 'cm_verify-fitment_vehicle' }, this.vehicleString || 'Vehicle'), _createElement('a', {
        'className': 'cm_verify-fitment_change',
        'onClick': this.changeVehicle
    }, 'Change'))))) : null, this.fits === 'no' ? _createElement('div', {
        'className': 'cm_verify-fitment_compatible-parts-container',
        'key': '10930'
    }, _createElement('a', {
        'href': this.getVehicleUrl(),
        'className': 'cm_verify-fitment_compatible-parts'
    }, 'Click here'), _createElement('span', {}, ' to shop parts that fit this vehicle.')) : null) : null, this.template === 'inexact' ? _createElement('div', {
        'className': 'cm_vehicle-widget_specifier cmTemplate_inexact',
        'key': '11211'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, 'Please specify ', this.fieldsToSpecify.join(', '), ' for your ', this.vehicleString), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects2.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button button button--primary button button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    ADD\n  ')))) : null, this.template === 'universal' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__universal cmTemplate_universal',
        'key': '17723'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'width': '20',
            'height': '21',
            'viewBox': '0 0 20 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '179220'
        }, _createElement('path', {
            'd': 'M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM14.59 6.08L8 12.67L5.41 10.09L4 11.5L8 15.5L16 7.5L14.59 6.08Z',
            'fill': '#2A7E56'
        }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'width': '20',
            'height': '21',
            'viewBox': '0 0 20 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '184460'
        }, _createElement('path', {
            'd': 'M9 13.5H11V15.5H9V13.5ZM9 5.5H11V11.5H9V5.5ZM9.99 0.5C4.47 0.5 0 4.98 0 10.5C0 16.02 4.47 20.5 9.99 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 9.99 0.5ZM10 18.5C5.58 18.5 2 14.92 2 10.5C2 6.08 5.58 2.5 10 2.5C14.42 2.5 18 6.08 18 10.5C18 14.92 14.42 18.5 10 18.5Z',
            'fill': '#EB4714'
        }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '189170'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null, this.fits === 'aggressive' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-exclamation',
            'height': '20',
            'viewBox': '0 0 256 255',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '194250'
        }, _createElement('path', {
            'fillRule': 'evenodd',
            'clipRule': 'evenodd',
            'd': 'M112.098 0.528293C105.702 1.30529 89.9175 5.21029 83.6615 7.56329C64.7805 14.6653 49.2545 25.1013 34.5985 40.5423C-0.248547 77.2563 -9.69655 131.035 10.4785 177.833C17.3025 193.66 23.8655 203.124 37.5985 216.939C51.1805 230.602 61.9924 238.186 77.0984 244.649C104.915 256.549 138.414 258.074 166.974 248.741C177.998 245.139 194.9 236.465 203.598 229.946C212.511 223.266 228.177 206.84 233.748 198.332C262.341 154.67 262.457 100.262 234.048 56.8423C227.749 47.2143 210.42 29.3733 201.129 22.9513C185.936 12.4473 166.593 4.56829 148.217 1.39729C140.191 0.0112929 120.292 -0.466707 112.098 0.528293ZM146.598 101.364V157.864H127.598H108.598V101.364V44.8643H127.598H146.598V101.364ZM136.699 179.648C141.014 181.902 142.56 183.448 144.814 187.763C148.264 194.367 148.343 198.988 145.115 205.418C138.666 218.265 122.327 220.292 112.789 209.428C104.371 199.842 107.303 185.18 118.78 179.461C125.482 176.121 130.038 176.169 136.699 179.648Z'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, window.Convermax.config?.verifyFitmentUniversalTitle || window.Convermax.config?.fitmentUniversalTitle || 'Universal Fit'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, 'This product is designed to fit most vehicle applications. Modification may be required.'))) : null, this.template === 'unknown' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__unknown cmTemplate_unknown',
        'key': '21003'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'width': '20',
            'height': '21',
            'viewBox': '0 0 20 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '211960'
        }, _createElement('path', {
            'd': 'M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM14.59 6.08L8 12.67L5.41 10.09L4 11.5L8 15.5L16 7.5L14.59 6.08Z',
            'fill': '#2A7E56'
        }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'width': '20',
            'height': '21',
            'viewBox': '0 0 20 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '217200'
        }, _createElement('path', {
            'd': 'M9 13.5H11V15.5H9V13.5ZM9 5.5H11V11.5H9V5.5ZM9.99 0.5C4.47 0.5 0 4.98 0 10.5C0 16.02 4.47 20.5 9.99 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 9.99 0.5ZM10 18.5C5.58 18.5 2 14.92 2 10.5C2 6.08 5.58 2.5 10 2.5C14.42 2.5 18 6.08 18 10.5C18 14.92 14.42 18.5 10 18.5Z',
            'fill': '#EB4714'
        }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '221910'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null, this.fits === 'aggressive' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-exclamation',
            'height': '20',
            'viewBox': '0 0 256 255',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '226990'
        }, _createElement('path', {
            'fillRule': 'evenodd',
            'clipRule': 'evenodd',
            'd': 'M112.098 0.528293C105.702 1.30529 89.9175 5.21029 83.6615 7.56329C64.7805 14.6653 49.2545 25.1013 34.5985 40.5423C-0.248547 77.2563 -9.69655 131.035 10.4785 177.833C17.3025 193.66 23.8655 203.124 37.5985 216.939C51.1805 230.602 61.9924 238.186 77.0984 244.649C104.915 256.549 138.414 258.074 166.974 248.741C177.998 245.139 194.9 236.465 203.598 229.946C212.511 223.266 228.177 206.84 233.748 198.332C262.341 154.67 262.457 100.262 234.048 56.8423C227.749 47.2143 210.42 29.3733 201.129 22.9513C185.936 12.4473 166.593 4.56829 148.217 1.39729C140.191 0.0112929 120.292 -0.466707 112.098 0.528293ZM146.598 101.364V157.864H127.598H108.598V101.364V44.8643H127.598H146.598V101.364ZM136.699 179.648C141.014 181.902 142.56 183.448 144.814 187.763C148.264 194.367 148.343 198.988 145.115 205.418C138.666 218.265 122.327 220.292 112.789 209.428C104.371 199.842 107.303 185.18 118.78 179.461C125.482 176.121 130.038 176.169 136.699 179.648Z'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, window.Convermax.config?.fitmentUnknownTitle || 'No Fitment Data'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, window.Convermax.config?.fitmentUnknownText || `This product doesn't have fitment application data. Please verify fitment manually.`))) : null);
}
        export const componentNames = ["cm:filterInput","cm:filterInput"]