import { bigcommerceAppClientId } from '../_common/constants.js';

const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/categories/';
const brandSelectionPageUrl = '/brands/';
const isFeaturedProductsPage = window.location.pathname.startsWith('/featured-products');

globalThis.Convermax.handleProductCompareClick = () => {
  const productsIdsToCompare = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.compareId,
  );

  const compareButton = window.document.body.querySelector('.cm_card-compare');

  if (!compareButton) {
    return;
  }

  const compareLink = window.document.body.querySelector('.cm_card-compare_link');
  const compareCount = window.document.body.querySelector('.cm_card-compare_count');

  compareCount.textContent = productsIdsToCompare.length;
  compareButton.classList.toggle('show', productsIdsToCompare.length > 1);
  compareLink.href = `/compare/${productsIdsToCompare.join('/')}/`;
};

function getLocalPreselection(pageType, defaults) {
  if (isFeaturedProductsPage) {
    return [{ field: 'is_featured', term: 'True' }];
  }

  return defaults.getter(pageType);
}

globalThis.stickyScroll = () => {
  const ymmContainerEl = document.querySelector('.cm_vehicle-widget-content');

  if (ymmContainerEl) {
    const headerEl = document.querySelector('header');

    const ymmVehicleWidgetEl = document.querySelector('.cm_vehicle-widget__sticky-container');

    if (ymmVehicleWidgetEl) {
      if (window.innerWidth < 801) {
        ymmContainerEl.classList.remove('isSticky');
        ymmVehicleWidgetEl.classList.remove('container');
        ymmContainerEl.style.top = 'auto';
        return;
      }

      const headerHeight = headerEl.clientHeight;
      const ymmStartPos = ymmContainerEl.parentElement.getBoundingClientRect();

      // use "+5" to get a more accurate if check
      if (ymmStartPos.top < headerHeight + 5) {
        ymmContainerEl.classList.add('isSticky');
        ymmVehicleWidgetEl.classList.add('container');
        ymmContainerEl.style.top = `${headerHeight}px`;
      } else {
        ymmContainerEl.classList.remove('isSticky');
        ymmVehicleWidgetEl.classList.remove('container');
        ymmContainerEl.style.top = 'auto';
      }
    }
  }
};

const customerGroupId = getGroupId();
async function getGroupId() {
  if (!globalThis.cm_currentUserId) {
    return '';
  }

  const groupId = await new Promise((resolve) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          const jwt = xmlHttp.responseText;
          const [, jsonPart] = jwt.split('.');
          const { customer } = JSON.parse(atob(jsonPart));
          resolve(customer.group_id);
        } else if (xmlHttp.status === 404) {
          resolve(null);
        } else {
          resolve(null);
        }
      }
    };
    xmlHttp.open('GET', `/customer/current.jwt?app_client_id=${bigcommerceAppClientId}`, true);
    xmlHttp.send();
  });
  return groupId || '';
}

function AfterInit() {
  window.addEventListener('scroll', window.stickyScroll);
  window.addEventListener('resize', window.stickyScroll);
}

const fieldsForRelated = ['category', ...wheelsFields, ...tiresFields];

function getSelection(itemData) {
  const selections = fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });

  return selections;
}

export default {
  platform: 'bigcommerce',
  AfterInit,
  getLocalPreselection,
  SearchRequestDefaults: {
    pageSize: 36,
    extra: {
      customerGroupId,
    },
  },
  product: {
    noImageSrc: '/images/stencil/original/image-manager/default-image.gif',
  },
  autocomplete: {
    requestDefaults: { extra: { customerGroupId } },
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ||
      window.location.pathname === brandSelectionPageUrl
        ? 'parts'
        : isFeaturedProductsPage
          ? 'page'
          : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { replace: 'main .page-content' },
      template: 'SearchPage',
      visibleIf: () => ['/search/', '/featured-products/'].includes(window.location.pathname),
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '.body .page',
      template: 'SearchPage',
      visibleIf: () =>
        window.document.querySelector(
          '[data-content-region=category_below_header], [data-content-region=brand_below_header]',
        ) &&
        window.location.pathname !== categorySelectionPageUrl &&
        window.location.pathname !== brandSelectionPageUrl,
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'date_created:desc': 'Newest',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'review_score:desc': 'Customer Review',
        'price': 'Price: Low - High',
        'price:desc': 'Price: High - Low',
      },
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
      initCollapsed: true,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBox',
      location: '.header-search',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { selector: '.quick-search-container' },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 550,
    },
    {
      name: 'HeaderVehicleWidget_garage',
      type: 'VehicleWidget',
      location: {
        replace: '#vehicle-list',
        id: 'vehicle-list',
        class: 'cm_vehicle-widget__garage',
      },
      template: 'fitmentSearch/collapsibleSingleVehicleGarage',
      initCollapsed: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'Garage',
      location: '#cm-garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SearchVehicleWidgetGarage',
      type: 'Garage',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      columnBreakpoint: 492,
    },
    {
      name: 'VerifyFitment',
      location: { lastChildOf: '.productView-details.product-options' },
      template: 'fitmentSearch/verifyFitment',
      columnBreakpoint: 550,
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: {
        insertAfter: '.productView-description .tabs li:first-child',
        wrapper: 'li',
        class: 'tab',
      },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: '.productView-description .tabs-contents',
        class: 'tab-content is-closed',
        id: 'tab-fitment',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '.body .page',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '.body .page',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      visibleIf: () => window.location.pathname === brandSelectionPageUrl,
    },
    {
      name: 'RelatedItems',
      location: { replace: '.productCarousel[data-list-name="Related Products"]' },
      template: 'product/relatedItems',
      count: 4,
      getSelection,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      initExpandedFacets: ['category'],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
