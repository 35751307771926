//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-904:_4180,_8016,_9116,_7492,_9528,_7684,_9248,_2316;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-904')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-904', "_4180,_8016,_9116,_7492,_9528,_7684,_9248,_2316");
        }
      }catch (ex) {
        console.error(ex);
      }